import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import imgOne from "../images/800X450.jpg"
import Layout from "../components/layout"
import Izjave from "../components/Izjave"
import SEO from "../components/seo"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      HWGraphQL {
        page(id: "cG9zdDo2OQ==") {
          content
          acfAbout {
            slike {
              cetrtaSlika {
                sourceUrl
              }
              drugaSlika {
                sourceUrl
              }
              prvaSlika {
                sourceUrl
              }
              tretjaSlika {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `)

  const {
    HWGraphQL: {
      page: {
        content,
        acfAbout: { slike },
      },
    },
  } = data

  const [slikeLink, setSlikeLink] = useState()

  useEffect(() => {
    if (slike) {
      setSlikeLink(slike)
    } else {
      return false
    }
  }, [])
  
  return (
    <Layout>
      <SEO title="O nas" />
      <section className="about pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-1">
              <h6 className="sub-title">Gostilna in picerija Jaka</h6>
              <h4>
                Pozdravljeni pri nas
                <span style={{display: 'none'}}>Jaka</span>
              </h4>
              {data ? (
                <div dangerouslySetInnerHTML={{__html: content }}></div>
              ) : (
                <p>Nalagam</p>
              )}

              <br />
            </div>
            <div className="col-md-4">
              <div className="">
                <div className="about-img mb-20">
                  <a
                    href={slikeLink ? slikeLink.prvaSlika.sourceUrl : imgOne}
                    className="popimg"
                  >
                    <div className="item-img">
                      <img
                        src={slikeLink ? slikeLink.prvaSlika.sourceUrl : imgOne}
                        alt="image"
                      />
                    </div>
                  </a>
                </div>
                <div className="about-img">
                  <a
                    href={slikeLink ? slikeLink.drugaSlika.sourceUrl : imgOne}
                    className="popimg"
                  >
                    <div className="item-img">
                      <img
                        src={
                          slikeLink ? slikeLink.drugaSlika.sourceUrl : imgOne
                        }
                        alt="image"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-30">
            <div className="col-md-10 offset-md-1">
              <div className="gallery text-center row">
                <div className="col-md-4 items">
                  <div className="about-img mb-20">
                    <a
                      href={
                        slikeLink ? slikeLink.tretjaSlika.sourceUrl : imgOne
                      }
                      className="popimg"
                    >
                      <div className="item-img">
                        <img
                          src={
                            slikeLink ? slikeLink.tretjaSlika.sourceUrl : imgOne
                          }
                          alt="image"
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 items">
                  <div className="about-img mb-20">
                    <a
                      href={
                        slikeLink ? slikeLink.cetrtaSlika.sourceUrl : imgOne
                      }
                      className="popimg"
                    >
                      <div className="item-img">
                        <img
                          src={
                            slikeLink ? slikeLink.cetrtaSlika.sourceUrl : imgOne
                          }
                          alt="image"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Izjave />
    </Layout>
  )
}

export default About
